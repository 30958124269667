import {
  get, post, downloadByGet, Delete,
} from '@/libs/axios'

export const DownloadFile = () => downloadByGet('/privacy/org/file/download')
export const DownloadEnterpriseTemplate = () => downloadByGet(`/enterprise/info/import_template?_t=${Date.parse(new Date())}`)
export const ExportEnterprise = () => downloadByGet(`/enterprise/info/export?_t=${Date.parse(new Date())}`)
export const getEnterpriseInfo = () => get('/enterprise/info/GetEnterpriseInfo')
export const updateEnterpriseInfo = params => post('/enterprise/info/UpdateEnterpriseInfo', params)
export const EnterpriseImport = params => post('/enterprise/info/import', params)
export const getPrivacyOrganizationStructure = () => get('/enterprise/privacyOrg/list')
export const QueryOrgRole = id => get(`/enterprise/privacyOrg/QueryOrgRole?id=${id}`)
export const createOrganizationRoleUser = params => post('/enterprise/privacyOrg/AddUser', params)
export const UpdateOrg = params => post('/enterprise/privacyOrg/UpdateOrg', params)
export const DeleteUser = params => post('/enterprise/privacyOrg/DeleteUser', params)
export const DeleteRole = params => post('/enterprise/privacyOrg/DeleteRole', params)
export const saveOrUpdateRole = params => {
  const submitUrl = params.id ? '/enterprise/privacyOrg/UpdateRole' : '/enterprise/privacyOrg/AddRole'
  return post(submitUrl, params)
}

export const PrivacyOrgList = () => get('/privacy/org/list')
export const GetOrgUserInfo = id => get(`/privacy/org/user/info?id=${id}`)
export const GetOrgRoleInfo = id => get(`/privacy/org/role/info?id=${id}`)
export const GetOrgInfo = id => get(`/privacy/org/info?id=${id}`)
export const PrivacyOrgpreview = () => get('/privacy/org/file/preview')
export const PrivacyOrgFileSave = params => post('/privacy/org/file/save', params)
// /privacy/org/role/delete
export const RoleDel = id => Delete(`/privacy/org/role/delete?id=${id}`)
export const OrgUserDel = id => Delete(`/privacy/org/delete/user?id=${id}`)
export const OrgDel = id => Delete(`/privacy/org/delete?id=${id}`)
//
export const saveOrUpdateOrgRole = params => {
  const submitUrl = params.id ? '/privacy/org/role/update' : '/privacy/org/role/add'
  return post(submitUrl, params)
}
//
export const saveOrUpdateOrg = params => {
  const submitUrl = params.id ? '/privacy/org/update' : '/privacy/org/create'
  return post(submitUrl, params)
}
