<template>
  <el-dialog
    :title="form.org_name"
    :visible.sync="dialogVisible"
    width="620px"
    @close="orgDialogClose"
    @open="dialogOpen"
  >
    <el-form
      v-if="dialogVisible"
      ref="form"
      v-loading="orgLoading"
      :model="form"
      label-width="120px"
    >
<!--      <el-form-item-->
<!--        label="所属层级"-->
<!--        prop="org_name"-->
<!--        :rules="[ { required: true, message: '名称不能为空', trigger: 'change' }]"-->
<!--      >-->
<!--        <el-input-->
<!--          v-model="form.org_name"-->
<!--          disabled-->
<!--        />-->
<!--      </el-form-item>-->
      <el-form-item
        label="隐私组织角色"
        prop="orgRoleId"
        :rules="[ { required: true, message: '隐私组织角色不能为空', trigger: 'change' }]"
      >
        <el-select
          v-model="form.orgRoleId"
          placeholder="请选择隐私组织角色"
        >
          <el-option
            v-for="(item,index) in roleOptions"
            :key="index"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="用户"
        prop="userId"
        :rules="[ { required: true, message: '用户不能为空', trigger: 'change' }]"
      >
<!--        <el-select-->
<!--          v-model="form.userId"-->
<!--          class="mr10"-->
<!--          placeholder="请选择人员"-->
<!--        >-->
<!--          <el-option-->
<!--            v-for="(item,index) in users"-->
<!--            :key="index"-->
<!--            :label="item.actualName"-->
<!--            :value="item.id"-->
<!--          />-->
<!--        </el-select>-->
          <div class="flex-start">
              <div class="mr10">已选择<font style="color: #0d40b2;font-size: 14px;margin: 0 5px">{{form.userId ? 1 : 0}}</font>个</div>
              <el-button @click="personSelectDialogShow"   size="small" type="primary" icon="el-icon-plus">添加人员</el-button>
          </div>
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button
        type="primary"
        :disabled="orgLoading"
        @click="orgSubmitForm"
      >
        提 交
      </el-button>
    </div>
      <PersonSelect :pUserId.sync="form.userId" ref="personSelect" />
  </el-dialog>
</template>

<script>
import { createOrganizationRoleUser, QueryOrgRole } from '@/api/enterprise/enterprise'
import { error, success } from '@core/utils/utils'
import { queryUserOptions } from '@/api/system/system'
import PersonSelect from '@core/components/person-select/PersonSelect'
export default {
  components: { PersonSelect },
  data() {
    return {
      dialogVisible: false,
      orgLoading: false,
      users: [],
      roleOptions: [],
      form: {
        org_name: '',
        orgId: '',
        orgRoleId: '',
        userId: '',
      },
    }
  },
  methods: {
    personSelectDialogShow() {
      this.$refs.personSelect.dialogVisible = true
    },
    dialogOpen() {
      this.getRoleOptions()
      this.getEnterpriseUersSelect()
    },
    getEnterpriseUersSelect() {
      queryUserOptions().then(res => {
        if (res.data.code === 0) {
          this.users = res.data.data
        }
      })
    },
    getRoleOptions() {
      QueryOrgRole(this.form.orgId).then(res => {
        const resData = res.data
        this.roleOptions = []
        if (resData.code === 0) {
          resData.data.forEach(r => {
            this.roleOptions.push({ label: r.roleName, value: r.id })
          })
        }
      })
    },
    orgDialogClose() {
      this.form = {
        org_name: '',
        orgId: '',
        orgRoleId: '',
        userId: '',
      }
    },
    orgSubmitForm() {
      // eslint-disable-next-line consistent-return
      this.$refs.form.validate(valid => {
        // eslint-disable-next-line no-empty
        if (valid) {
          this.orgLoading = true
          createOrganizationRoleUser(this.form).then(res => {
            this.orgLoading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.dialogVisible = false
              this.$emit('handleFilter')
            } else {
              error(resData.msg)
            }
          })
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
