import { downloadByGet, get, post } from '@/libs/axios'

export const QueryDeptOptions = () => get('/system/dept/select')
export const ListDept = searchStr => {
  const url = searchStr ? `/system/dept/ListDept?searchStr=${searchStr}` : '/system/dept/ListDept'
  return get(url)
}
export const DeleteDeptUser = params => post('/system/dept/DeleteDeptUser', params)
export const ImportDept = params => post('/system/dept/import', params)
export const ExportDept = () => downloadByGet(`/system/dept/export?_t=${Date.parse(new Date())}`)
export const DownloadDeptImportTemplate = () => downloadByGet(`/system/dept/import_template?_t=${Date.parse(new Date())}`)
export const DelDept = id => post('/system/dept/DelDept', id)
export const QueryUserListByDeptId = id => get(`/system/dept/QueryUserList?id=${id}`)
export const AddDeptUser = params => post('/system/dept/AddDeptUser', params)

export const saveOrUpdateDepts = params => {
  const submitUrl = params.id ? '/system/dept/UpdateDept' : '/system/dept/CreatDept'
  return post(submitUrl, params)
}

export const AssignDeptPrincipal = params => post('/system/dept/AssignDeptPrincipal', params)
export const DeleteCurrentDeptUser = params => post('/system/dept/DeleteCurrentDeptUser', params)
