<template>
  <el-dialog
    :title="form.id ? '编辑角色' : '新增角色'"
    :visible.sync="dialogVisible"
    width="620px"
    @close="dialogClose"
    @open="dialogOpen"
  >
    <el-form
      v-if="dialogVisible"
      ref="form"
      v-loading="formLoading"
      :model="form"
      label-width="80px"
    >
      <el-form-item
        label="所属组织"
      >
        <el-select
          v-model="form.orgId"
          disabled
          placeholder="请选择所属组织"
        >
          <el-option
            v-for="(item,index) in orgList"
            :key="index"
            :label="item.orgName"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="角色名称"
        prop="roleName"
        :rules="[ { required: true, message: '角色名称不能为空', trigger: 'change' }]"
      >
        <el-input v-model="form.roleName" />
      </el-form-item>
      <el-form-item
        label="角色职责"
      >
        <el-input
          v-model="form.responsibility"
          type="textarea"
          :autosize="{ minRows: 5}"
        />
      </el-form-item>
      <el-form-item
        label="参与人员"
        prop="userIdList"
        :rules="[ { required: true, message: '人员不能为空', trigger: 'change' }]"
      >
        <el-select
          v-model="form.userIdList"
          multiple
          placeholder="请选择人员"
        >
          <el-option
            v-for="(item,index) in users"
            :key="index"
            :label="item.actualName || item.nickName"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item
          label="排序"
          prop="sortNo"
      >
        <el-input
            v-model.number="form.sortNo"
            type="number"
        />
      </el-form-item>
    </el-form>

    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button
        type="primary"
        @click="submitForm"
      >
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { GetOrgRoleInfo, PrivacyOrgList, saveOrUpdateOrgRole } from '@/api/enterprise/enterprise'
import { error, success } from '@core/utils/utils'
import { queryUserOptions } from '@/api/system/system'

export default {
  data() {
    return {
      dialogVisible: false,
      formLoading: false,
      form: {
        id: 0,
        orgId: '',
        roleName: '',
        roleType: 1,
        responsibility: '',
        userIdList: [],
        sortNo: 0,
      },
      users: [],
      orgList: [],
    }
  },
  methods: {
    // 查询角色信息
    getPrivacyOrgList() {
      PrivacyOrgList().then(res => {
        this.loading = false
        const resData = res.data
        if (resData.code === 0) {
          this.orgList = resData.data
        }
      })
    },
    getOrgRoleInfo() {
      GetOrgRoleInfo(this.form.id).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.form = resData.data
        }
      })
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.formLoading = true
          if (this.form.id) {
            delete this.form.org_name
          }
          saveOrUpdateOrgRole(this.form).then(res => {
            this.formLoading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.dialogVisible = false
              this.$emit('handleFilter', this.form.orgId)
            } else {
              error(resData.msg)
            }
          })
        }
      })
    },
    dialogOpen() {
      this.getPrivacyOrgList()
      this.getUsers()
      if (this.form.id) {
        this.getOrgRoleInfo()
      }
    },
    getUsers() {
      queryUserOptions().then(res => {
        if (res.data.code === 0) {
          this.users = res.data.data
        }
      })
    },
    dialogClose() {
      this.form = {
        id: 0,
        orgId: '',
        roleName: '',
        roleType: 1,
        responsibility: '',
        userIdList: [],
      }
    },
  },
}
</script>

<style scoped>

</style>
