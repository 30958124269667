<template>
  <el-dialog title="选择人员"
             :visible.sync="dialogVisible"
             width="800px"
             :append-to-body="true"
             :modal-append-to-body="false"
             @close="dialogClose"
             @opened="dialogOpen">
    <b-row class="match-height">
      <b-col md="6">
        <b-card title="部门"
                v-loading="treeLoading">
          <el-input class="mb10"
                    v-model="deptSearch"
                    @input="listDept"
                    placeholder="请输入关键字搜索" />
          <el-tree id="tree-option"
                   ref="elTree"
                   :highlight-current="true"
                   :accordion="true"
                   :data="deptOptions"
                   :props="treeSelectProps"
                   :node-key="'id'"
                   :default-expanded-keys="defaultExpandedKey"
                   @node-click="handleNodeClick">
          </el-tree>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card title="人员">
          <div>
            <el-input v-model="userSearch"
                      @input="searchUser"
                      @clear="searchUser"
                      clearable
                      class="mb10"
                      placeholder="请输入关键字搜索" />
            <div v-if="users.length > 0"
                 v-loading="userLoading">
              <div v-if="multiple">
                <!--<el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>-->
                <el-checkbox-group v-model="userIds">
                  <div v-for="user in users">
                    <el-checkbox :label="user.id">{{user.actualName}}</el-checkbox>
                  </div>
                </el-checkbox-group>
              </div>
              <div v-else>
                <el-radio-group v-model="userId">
                  <div v-for="user in users">
                    <el-radio :label="user.id">{{user.actualName}}</el-radio>
                  </div>
                </el-radio-group>
              </div>
            </div>
            <el-empty v-else
                      description="暂无数据"></el-empty>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <div slot="footer"
         class="dialog-footer">
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button type="primary"
                 @click="selectPerson">
        确 定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { ListDept } from '@/api/system/dept/dept'
import { treeDataTranslate } from '@core/utils/utils'
import { QueryDeptUsers } from '@/api/system/system'

export default {
  name: 'PersonSelect',
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    pUserId: {
      type: null,
      default: ''
    },
    pUserIds: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      deptSearch: '',
      userSearch: '',
      currentDeptId: 0,
      treeLoading: false,
      isIndeterminate: true,
      userIds: [],
      expandedKeys: [],
      userId: '',
      users: [],
      allUsers: [],
      // checkUsers: [],
      dialogVisible: false,
      treeSelectProps: {
        value: 'id', // ID字段名
        label: 'deptName', // 显示名称
        children: 'children', // 子级字段名
      },
      deptOptions: [],
      defaultExpandedKey: [],
      init: true,
      checkAll: false,
      userLoading: false,
    }
  },
  methods: {
    searchUser () {
      setTimeout(() => {
        if (this.userSearch) {
          this.users = this.users.filter(user => user.actualName.indexOf(this.userSearch) !== -1)
        } else {
          this.users = this.allUsers
        }

      }, 1000)
    },
    selectPerson () {
      // 多选
      if (this.multiple) {
        this.$emit('update:pUserIds', this.userIds)
      } else {
        if (this.userId) {
          this.$emit('update:pUserId', this.userId)
        }
      }
      this.dialogVisible = false
    },
    handleCheckAllChange (val) {
      this.userIds = val ? val : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange (value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.users.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.users.length;
    },
    dialogClose () { },
    dialogOpen () {
      if (this.multiple) {
        this.userIds = this.pUserIds
      }
      if (this.pUserId) {
        this.userId = this.pUserId
      }
      if (this.init) {
        this.listDept()
        this.queryUserListByDeptId()
      }
      this.init = false
    },
    listDept () {
      this.treeLoading = true
      ListDept(this.deptSearch).then(res => {
        this.treeLoading = false
        const resData = res.data
        if (resData.code === 0) {
          this.deptOptions = treeDataTranslate(resData.data, 'id', 'parentId')
          // if(this.deptOptions.length > 0) {
          //   var currentDeptId = ''
          //   if(this.deptOptions[0].children){
          //     currentDeptId = this.deptOptions[0].children[0].id
          //   }else{
          //     currentDeptId = this.deptOptions[0].id
          //   }
          //   this.expandedKeys.push(currentDeptId)
          //   this.$nextTick(() => {
          //     this.$refs.elTree.setCurrentKey(currentDeptId)
          //   })
          //   this.queryUserListByDeptId(currentDeptId)
          // }
        }
      })
    },
    queryUserListByDeptId () {
      this.userLoading = true
      QueryDeptUsers({ deptId: this.currentDeptId, searchStr: this.deptSearch }).then(res => {
        this.userLoading = false
        const resData = res.data
        if (resData.code === 0) {
          this.users = resData.data
          this.allUsers = resData.data
        }
      }).catch(() => {
        this.userLoading = false
      })
    },
    handleNodeClick (node) {
      if (this.currentDeptId === node.id) {
        this.currentDeptId = 0
        this.$refs.elTree.setCurrentKey(null)
      } else {
        this.currentDeptId = node.id
      }
      this.queryUserListByDeptId()
    },
  }
}
</script>

<style scoped>
</style>
