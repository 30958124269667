<template>
  <el-dialog
    :title="form.id ? '编辑组织' : '添加组织'"
    :visible.sync="dialogVisible"
    width="620px"
    @close="orgDialogClose"
    @open="open"
  >
    <el-form
      v-if="dialogVisible"
      ref="form"
      v-loading="orgLoading"
      :model="form"
      label-width="120px"
      class="mr10"
    >
      <el-form-item
        label="隐私组织名称"
        prop="orgName"
        :rules="[ { required: true, message: '隐私组织名称不能为空', trigger: 'change' }]"
      >
        <el-input
          v-model="form.orgName"
        />
      </el-form-item>
      <el-form-item
        label="排序"
      >
        <el-input
          v-model.number="form.sortNo"
          type="number"
        />
      </el-form-item>
      <el-form-item
        label="职责说明"
      >
        <el-input
          v-model="form.responsibility"
          :autosize="{ minRows: 5}"
          type="textarea"
        />
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button
        type="primary"
        @click="orgSubmitForm"
      >
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { GetOrgInfo, saveOrUpdateOrg } from '@/api/enterprise/enterprise'
import { error, success } from '@core/utils/utils'

export default {
  data() {
    return {
      dialogVisible: false,
      orgLoading: false,
      form: {
        id: 0,
        responsibility: '',
        orgName: '',
        sortNo: 0,
      },
    }
  },
  methods: {
    // 查询组织信息
    getOrgInfo() {
      GetOrgInfo(this.form.id).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.form = resData.data
        }
      })
    },
    open() {
      if (this.form.id) {
        this.getOrgInfo()
      }
    },
    orgSubmitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.formLoading = true
          this.form.sortNo = this.form.sortNo ? this.form.sortNo : 0
          saveOrUpdateOrg(this.form).then(res => {
            this.formLoading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.dialogVisible = false
              this.$emit('handleFilter')
            } else {
              error(resData.msg)
            }
          })
        }
      })
    },
    orgDialogClose() {
      this.form = {
        id: 0,
        responsibility: '',
        orgName: '',
      }
    },
  },
}
</script>

<style scoped>

</style>
