<template>
  <div>
    <PageIntroduce
      :title="'隐私管理团队'"
      :desc="'企业应按照法律法规设立专职的个人信息保护管理负责人和个人信息保护管理组织，负责个人信息保护的各项工作。'"
      :tips="'中国个人信息保护法 第五十一条'"
    />

    <div class="flex-between mb10">
      <el-button
        type="primary"
        v-if="saveOrganizationPerm"
        @click="organizationUpdateDialogShow(1)"
      >
        添加组织
      </el-button>
      <div class="flex-end">
        <el-button
          v-if="exportPerm"
          size="mini"
          type="primary"
          @click="downloadFile"
        >
          下载
        </el-button>
        <el-button
          v-if="readPerm"
          size="mini"
          type="primary"
          @click="$router.push('/privacy-read')"
        >
          预览
        </el-button>
      </div>

    </div>

    <b-row class="match-height">
      <b-col
        v-loading="pageLoading"
        xl="8"
        md="6"
      >
        <div v-if="orgList.length > 0">
          <b-card
            v-for="(item,index) in orgList"
            :key="index"
            class="card-no-padding"
          >
            <VuePerfectScrollbar
              :key="index"
            >
              <div :style="{height: 180 + 'px'}">
                <zm-tree-org
                  ref="tree"
                  :data="item"
                  :props="{children: 'privacyUserList'}"
                  :define-menus="[]"
                  :disabled="disaled"
                  :scalable="false"
                  :horizontal="horizontal"
                  :collapsable="collapsable"
                  :draggable="false"
                  :clone-node-drag="false"
                  :label-style="style"
                  :default-expand-level="1"
                  :only-one-node="onlyOneNode"
                  :tool-bar="toolBar"
                >
                  <template v-slot="{node}">
                    <!--                    :class="activeIndex === index && node.orgLevel === 0 ? 'org-active' :''"-->

                    <div
                      :class="activeId === node.id && activeLevel === node.$$level  ? 'org-active' :''"
                      class="tree-org-node__text node-label"
                      @click="orgTitleClick(node,index)"
                    >
                      <div class="tree-operate-btn">
                        <i
                          v-if="node.orgLevel === 0 && updateOrganizationPerm "
                          class="el-icon-edit mr10"
                          @click.stop="organizationUpdateDialogShow(2,node)"
                        />
                        <i
                            v-if="delUserPerm"
                          class="el-icon-delete"
                          @click.stop="delOrgOrUser(node)"
                        />
                      </div>
                      <div style="word-break: keep-all;white-space: nowrap">
                        {{ node.orgLevel === 0 ? node.orgName : node.roleName + ':' + node.userName }}
                      </div>
                    </div>
                  </template>
                  <template v-slot:expand="{node}">
                    <div>{{ node.privacyUserList.length }}</div>
                  </template>
                </zm-tree-org>
              </div>
            </VuePerfectScrollbar>
          </b-card>
        </div>
        <b-card
          v-else
          class="text-center"
        >
          <el-empty
            description="暂无数据"
            :image-size="200"
          />
        </b-card>
      </b-col>
      <b-col
        xl="4"
        md="6"
      >
        <b-card
          v-if="rightShowType === 1"
          v-loading="pageLoading"
        >
          <div class="mb20">
            <div class="orgaization-item-top flex-between">
              <span class="org-role-title">组织职责</span>
            </div>
            <div v-if="orgInfo.responsibility">
              <div
                class="org-desc-label"
                v-html="orgInfo.responsibility"
              />
            </div>
            <el-empty
              v-else
              description="暂无数据"
              :image-size="50"
            />
          </div>
          <el-divider style="height:20px; margin-top:10px" />
          <div>
            <div class="orgaization-item-top flex-between">
              <span class="org-role-title">成员角色</span>
              <el-link
                v-if="saveRolePerm"
                :underline="false"
                size="mini"
                type="primary"
                @click="addOrganizationRoleDialogShow(1)"
              >
                <el-tooltip
                  content="新增角色"
                  placement="top"
                  effect="light"
                >
                  <i class="el-icon-circle-plus-outline" />
                </el-tooltip>
              </el-link>
            </div>
            <el-table
              :max-height="pageH - 80"
              border
              fit
              highlight-current-row
              :data="tableData"
              stripe
              style="width: 100%"
            >
              <el-table-column
                prop="roleName"
                :show-overflow-tooltip="true"
                width="120"
                label="角色名称"
              />
              <el-table-column
                prop="responsibility"
                :show-overflow-tooltip="true"
                label="职责"
              />
              <el-table-column
                prop="userNames"
                :show-overflow-tooltip="true"
                label="人员"
              />
              <el-table-column
                label="操作"
                width="120px"
              >
                <template slot-scope="{row}">
                  <el-link
                      v-if="updateRolePerm"
                    class="mr10"
                    type="primary"
                    @click="addOrganizationRoleDialogShow(2,row)"
                  >
                    <el-tooltip
                      content="修改"
                      placement="top"
                      effect="light"
                    >
                      <i class="el-icon-edit" />
                    </el-tooltip>
                  </el-link>
                  <el-link
                      v-if="delRolePerm"
                    type="primary"
                    @click="delRole(row)"
                  >
                    <el-tooltip
                      content="删除"
                      placement="top"
                      effect="light"
                    >
                      <i class="el-icon-delete" />
                    </el-tooltip>
                  </el-link>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </b-card>
        <b-card v-else>
          <el-descriptions
            title="用户信息"
            :column="1"
            :label-style="{width: '100px'}"
            border
          >
            <el-descriptions-item label="所属隐私组织">
              {{ currentUserInfo.orgName }}
            </el-descriptions-item>
            <el-descriptions-item label="隐私组织角色">
              {{ currentUserInfo.roleName }}
            </el-descriptions-item>
            <el-descriptions-item label="职责">
              <div style="white-space: pre-line" v-html="currentUserInfo.responsibility"></div>
            </el-descriptions-item>
            <el-descriptions-item label="人员姓名">
              {{ currentUserInfo.userName }}
            </el-descriptions-item>
            <el-descriptions-item label="所属部门">
              {{ currentUserInfo.deptName }}
            </el-descriptions-item>
            <el-descriptions-item label="职位">
              {{ currentUserInfo.jobTitle }}
            </el-descriptions-item>
          </el-descriptions>
        </b-card>
      </b-col>
    </b-row>
    <OrganizationUpdateDiaolg
      ref="organizationUpdateDiaolg"
      @handleFilter="getPrivacyOrgList"
    />
    <AddOrganizationRoleDialog
      ref="addOrganizationRoleDialog"
      @handleFilter="getPrivacyOrgList"
    />
    <AddUser
      ref="addUser"
      @handleFilter="getPrivacyOrgList"
    />
  </div>
</template>

<script>
import {
  DownloadFile,
  PrivacyOrgList,
  GetOrgInfo,
  RoleDel,
  OrgUserDel, OrgDel, GetOrgUserInfo,
} from '@/api/enterprise/enterprise'
import {
  error, success, getBtnPerms, verifyBtnPerm,
} from '@core/utils/utils'
import PageIntroduce from '@core/components/pageIntroduce/PageIntroduce.vue'
import OrganizationUpdateDiaolg from '@/views/privacyFramework/organization/OrganizationUpdateDiaolg.vue'
import AddOrganizationRoleDialog from '@/views/privacyFramework/organization/AddOrganizationRoleDialog.vue'
import AddUser from '@/views/privacyFramework/organization/AddUser'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import 'vue-organization-chart/dist/orgchart.css'

export default {
  name: 'Organization',
  components: {
    PageIntroduce, OrganizationUpdateDiaolg, AddOrganizationRoleDialog, AddUser, VuePerfectScrollbar,
  },
  data() {
    return {
      baseUrl: window.g.baseUrl,
      accessToken: localStorage.getItem('accessToken'),
      pageH: document.body.clientHeight - 200,
      exportPerm: false,
      delOrganizationPerm: false,
      saveUserPerm: false, // 新增成员
      delUserPerm: false, // 删除成员
      saveOrganizationPerm: false,
      updateOrganizationPerm: false,
      saveRolePerm: false, // 新增角色
      updateRolePerm: false, // 修改角色
      delRolePerm: false, // 删除角色
      readPerm: false, // 删除角色
      pdfDialogVisible: false,
      pageLoading: false,
      activeIndex: 0,
      orgList: [],
      tableData: [],
      currentOrgId: 0,
      currentUerId: 0,
      currentUserInfo: {
        deptName: '',
        jobTitle: '',
        orgName: '',
        responsibility: '',
        roleName: '',
        userName: '',
      },
      orgInfo: {},
      toolBar: {
        scale: false,
        restore: false,
        expand: false,
        zoom: false,
        fullscreen: false,
      },
      keyword: '',
      horizontal: false,
      collapsable: false,
      onlyOneNode: true,
      cloneNodeDrag: false,
      expandAll: true,
      disaled: false,
      style: {
        background: '#fff',
        color: '#5e6d82',
      },
      rightShowType: 1, // 1显示组织信息 2显示人员信息
      activeId: 0,
      activeLevel: 0,
    }
  },
  created() {
    getBtnPerms(btnPerms => {
      this.delOrganizationPerm = verifyBtnPerm(btnPerms, 'policy-organization-7')
      this.saveUserPerm = verifyBtnPerm(btnPerms, 'policy-organization-3') // 新增成员
      this.delUserPerm = verifyBtnPerm(btnPerms, 'policy-organization-8') // 删除成员
      this.saveOrganizationPerm = verifyBtnPerm(btnPerms, 'policy-organization-4')
      this.updateOrganizationPerm = verifyBtnPerm(btnPerms, 'policy-organization-11')
      this.saveRolePerm = verifyBtnPerm(btnPerms, 'policy-organization-4')// 新增角色
      this.updateRolePerm = verifyBtnPerm(btnPerms, 'policy-organization-5')// 新增角色
      this.delRolePerm = verifyBtnPerm(btnPerms, 'policy-organization-6')// 删除角色
      this.readPerm = verifyBtnPerm(btnPerms, 'policy-organization-9')// 预览角色
      this.exportPerm = verifyBtnPerm(btnPerms, 'policy-organization-10')
    })
    this.getPrivacyOrgList()
  },
  methods: {
    // 修改隐私组织
    organizationUpdateDialogShow(type, node) {
      this.$refs.organizationUpdateDiaolg.dialogVisible = true
      if (type === 2) {
        this.$refs.organizationUpdateDiaolg.form.id = node.id
      } else {
        this.$refs.organizationUpdateDiaolg.form.sortNo = this.orgList.length + 1
      }
    },
    delOrgOrUser(row) {
      this.$confirm('是否确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        if (row.orgLevel === 0) {
          OrgDel(row.id).then(res => {
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.getPrivacyOrgList()
            } else {
              error(resData.msg)
            }
          })
        } else {
          OrgUserDel(row.id).then(res => {
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.getPrivacyOrgList(this.activeId)
            } else {
              error(resData.msg)
            }
          })
        }
      })
    },
    delRole(row) {
      this.$confirm('是否确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        RoleDel(row.id).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            success(resData.msg)
            this.getPrivacyOrgList(row.orgId)
          } else {
            error(resData.msg)
          }
        })
      })
    },
    addOrganizationRoleDialogShow(type, role) {
      this.$refs.addOrganizationRoleDialog.dialogVisible = true
      this.$refs.addOrganizationRoleDialog.form.orgId = Number(this.orgInfo.id)
      this.$refs.addOrganizationRoleDialog.form.orgName = this.orgInfo.orgName
      if (type === 2) {
        this.$refs.addOrganizationRoleDialog.form.id = role.id
      }
    },
    getOrgUserInfo() {
      GetOrgUserInfo(this.currentUerId).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.currentUserInfo = resData.data
        }
      })
    },
    orgTitleClick(item, index) {
      console.log(item)
      // console.log(index)
      this.activeId = item.id
      const { $$level } = item
      this.activeLevel = $$level
      this.rightShowType = $$level + 1
      if (this.rightShowType === 1) {
        this.activeIndex = index
        this.currentOrgId = item.id
        this.currentUerId = 0
        this.getOrgInfo()
      } else {
        this.currentUerId = item.id
        this.getOrgUserInfo()
      }
    },
    // 查询组织信息
    getOrgInfo() {
      GetOrgInfo(this.currentOrgId).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.orgInfo = resData.data
          this.tableData = this.orgInfo.privacyRoleList ? this.orgInfo.privacyRoleList : []
          if (this.orgInfo.responsibility) {
            this.orgInfo.responsibility = this.orgInfo.responsibility.replace(/\\n/g, '<br>')
          }
        }
      })
    },
    getPrivacyOrgList(currentOrgId) {
      this.loading = true
      this.rightShowType = 1
      PrivacyOrgList().then(res => {
        this.loading = false
        const resData = res.data
        if (resData.code === 0) {
          this.orgList = resData.data
          if (currentOrgId !== undefined) {
            this.currentOrgId = currentOrgId
            this.activeId = currentOrgId
            this.activeLevel = 0
          } else if (this.orgList.length > 0) {
            this.currentOrgId = this.orgList[0].id
            this.currentUerId = this.orgList[0].id
            this.activeId = this.currentOrgId
            this.activeLevel = 0
          } else {
            this.orgInfo.responsibility = ''
            this.tableData = []
          }
          this.getOrgInfo()
        } else {
          error(resData.msg)
        }
      }).catch(() => {
        this.loading = false
      })
    },
    downloadFile() {
      DownloadFile()
    },
    preview() {
      window.open(`${this.baseUrl}/enterprise/privacyOrg/Preview?x-token=${this.accessToken}`, '_blank')
    },
  },
}
</script>

<style lang="scss" >
.card-no-padding{
  .card-body{
    padding: 0 !important;
  }
}
   .el-tag.active-tag {
    background-color: rgb(13, 64, 178) !important;
    border-color: rgb(13, 64, 178) !important;
     color: #ffffff !important;
     .el-tag__close{
       color: #ffffff !important;
     }
  }
  .orgActive{
    color:#0d40b2;
  }
  .org-desc-label{
    white-space: pre-line;
    font-size: 12px;
    margin: 2px 5px 0px 12px;
    line-height: 2rem;
    /*text-indent: 20px;*/
  }
  .orgaization-wrap{
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .suggester{
    background-color: #ecf5ff;
    border-color: #d9ecff;
    border-radius: 4px;
    padding: 10px;
    font-size: 12px;
    color: #409EFF;
  }
  .orgaization-item-tag{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    cursor: pointer;
    .el-tag__close{
      display: none;
      /*opacity: 0;*/
    }
  }
  .orgaization-item-tag .el-tag:hover{
    .el-tag__close{
      display: inline-block;
      /*opacity: 1;*/
    }
  }
  .orgaization-item-tag .el-tag{
    margin: 10px 0 10px 12px;
    cursor: pointer;
  }
  .icon-xia--jiantou{
    font-size: 36px;
    color: #ff8e66;
  }
  .orgaization-table{
    width: 100%;
    text-align: center
  }
  .orgaization-table td,
  .orgaization-table th{
    border: 1px solid #ededed;
    height: 40px;
  }

  .org-title{
    border-left: solid 6px #0d40b2;
    padding-left: 8px;
    margin-bottom: 2px;
    font-size: 14px;
  }
  .org-role-title{
    border-left: solid 6px #0d40b2;
    padding-left: 8px;
    margin-bottom: 2px;
    font-size: 14px;
  }
  .el-divider--horizontal{
    display: block;
    height: 1px;
    width: 100%;
    margin: -10px 0px 10px 0px;
  }
</style>
<style lang="scss">
 .tree-operate-btn{
   position: absolute;
   top: 4px;
   z-index: 2;
   right: 10px;
   display: none;
   i{
     cursor: pointer;
     font-size: 16px;
   }
 }
 .tree-org-node__content .tree-org-node__inner{
   cursor: pointer;
   padding: 5px 10px;
 }
 .tree-org-node__content:hover .tree-operate-btn,
 .tree-org-node__inner:hover .tree-operate-btn,
 .tree-org-node__text:hover .tree-operate-btn{
   display: block;
 }
 .zoom-container{
   display: flex;
   justify-content: center;
   //overflow: scroll !important;
 }
 .zm-draggable{
   //width: 100%;
   overflow-x: scroll !important;
   position: relative !important;
 }
 .tree-org-node__inner{
   padding: 0 !important;
   background: transparent !important;

 }
 .tree-org-node__inner{
   //padding: 10px 20px !important;
   border: 1px solid #ddd;
   box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.15) !important;
 }
.org-active{
   background: #0b379a !important;
   color: #FFFFFF !important;
 }
.user-active{
  background: #7367f0 !important;
  color: #FFFFFF !important;
}
 .tree-org-node__content .tree-org-node__text {
   //padding: 20px 40px;
   min-width: 120px;
 }
</style>
